import React, { forwardRef } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { twMerge } from "tailwind-merge";
import { PulseLoader } from "react-spinners"; // Import loader component
import Typography from "../Typography";

const Button = forwardRef((props, ref) => {
  const {
    className,
    variant = "primary",
    size = "lg",
    icon,
    textSize = "lg",
    children,
    disabled,
    loading,
    ...rest
  } = props;

  const generalStyles = [
    "px-4 rounded-lg text-white flex justify-center items-center font-medium tracking-[.25px] outline-none space-x-2",
    "min-w-[3rem] min-h-[2.5rem]",
  ];

  // Sizes
  const small = ["text-xs py-3 h-10"];
  const large = ["text-sm py-3.5 h-12"];

  // Main color
  const primary = [
    "bg-primary", // Default
    "focus:bg-primary-focus focus:text-on-surface-white-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-hover [&:hover:not(:disabled)]:text-on-surface-white-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];
  const secondary = [
    "bg-secondary", // Default
    "focus:bg-secondary-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
  ];

  // Outline
  const outlinePrimary = [
    "border border-stroke-dark-12 bg-white text-primary", // Default
    "focus:bg-primary-soft-while-pressing focus:text-on-surface-black-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-on-surface-black-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];
  const outlineSecondary = [
    "border border-stroke-secondary-20 bg-white text-on-surface-secondary", // Default
    "focus:bg-secondary-soft-focus focus:border-stroke-secondary-12", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-stroke-secondary-12", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24 disabled:border-stroke-secondary-12", // Disabled
  ];

  // Soft color
  const softPrimary = [
    "bg-primary-soft text-primary", // Default
    "focus:bg-primary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-hover", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];
  const softSecondary = [
    "bg-secondary-soft text-on-surface-secondary", // Default
    "focus:bg-secondary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
  ];

  return (
    <button
      {...rest}
      ref={ref}
      disabled={disabled || loading}
      className={twMerge([
        generalStyles,
        size === "sm" && small,
        size === "lg" && large,
        variant === "primary" && primary,
        variant === "secondary" && secondary,
        variant === "outlinePrimary" && outlinePrimary,
        variant === "outlineSecondary" && outlineSecondary,
        variant === "softPrimary" && softPrimary,
        variant === "softSecondary" && softSecondary,
        className,
      ])}
    >
      {loading ? (
        <PulseLoader color="#BBC1C9" speedMultiplier={0.7} size={12} />
      ) : (
        <>
          {icon}
          <Typography variant="Label" size={textSize}>
            {children}
          </Typography>
        </>
      )}
    </button>
  );
});

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.node,
  textSize: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
