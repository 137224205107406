import Typography from "../components/Typography";
import AgulaLogo from "../base-components/Icon/AgulaLogo";

export default function NotFound() {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 bg-[#1F252F]">
      <AgulaLogo />
      <Typography
        size={30}
        weight={600}
        className="flex w-full items-center justify-center text-white"
      >
        Хуудас олдсонгүй
      </Typography>
      <a
        href="/"
        className="flex w-full items-center justify-center text-white transition-all hover:text-secondary"
      >
        <Typography size={20} weight={400}>
          Нүүр хуудасруу очих
        </Typography>
      </a>
    </div>
  );
}
