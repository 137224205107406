import Service from "./BaseService";

const getInsurancePolicies = (payload, config) =>
  Service.post(
    `/dynamicInsurance/get-insurance-policies-broker`,
    payload,
    config,
  );

const getInsurancePolicyDtl = (policy_id) =>
  Service.get(
    `/dynamicInsurance/get-insurance-policy-dtl?policy_id=${policy_id}`,
  );

const savingInsurancePolicy = (payload) =>
  Service.post(`/dynamicInsurance/set-insurance-policy`, payload);

const getInsurancePolicyFileUrl = (id, config) =>
  Service.get(
    `/dynamicInsurance/get-insurance-policy-file-url?policy_id=${id}`,
    config,
  );

const PolicyInfoService = {
  getInsurancePolicies,
  getInsurancePolicyDtl,
  savingInsurancePolicy,
  getInsurancePolicyFileUrl,
};

export default PolicyInfoService;
