import * as React from "react";

const Eye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    aria-hidden="true"
    className="MuiBox-root css-1t9pz9x iconify iconify--eva"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={12} cy={12} r={1.5} fill="currentColor" />
    <path
      fill="currentColor"
      d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1m-9.87 4a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5"
    />
  </svg>
);
export default Eye;
