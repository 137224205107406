import * as React from "react";
const AgulaLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={92}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M8.248 14.123C8.232 6.452 8.28.114 8.359.066 8.44.003 22.511-.029 70.781.035l.032 13.628c.032 10.8 0 13.612-.143 13.612-.111 0-2.208-2.002-4.654-4.448-2.446-2.446-4.527-4.447-4.606-4.431-.08 0-1.477 1.398-3.097 3.097-1.62 1.7-3.018 3.082-3.098 3.082-.08 0-3.51-3.352-7.624-7.466-4.114-4.113-7.529-7.465-7.624-7.465-.08 0-3.59 3.431-15.407 15.248l-3.097-3.097c-1.7-1.7-3.177-3.097-3.272-3.097-.08 0-2.271 2.112-4.844 4.685-2.558 2.573-4.766 4.686-4.877 4.686-.143 0-.19-2.907-.222-13.946Z"
    />
    <path
      fill="#fff"
      d="M26.99 32.167c-.031-.111 2.844-3.082 6.386-6.608 3.541-3.51 6.528-6.369 6.623-6.353.111.032 5.432 5.29 11.833 11.674 6.401 6.401 11.69 11.643 11.77 11.643.063 0 .032-.19-.08-.429-.11-.254-1.397-2.7-5.543-10.451l.445-.477c.238-.254.985-1.064 1.652-1.779.65-.73 1.302-1.318 1.43-1.318.126 0 4.288 4.05 9.26 9.006 6.797 6.798 8.99 9.085 8.894 9.26-.063.127-4.019 4.193-8.784 9.006-4.765 4.828-8.767 8.783-8.863 8.783-.11 0-3.573-3.335-7.671-7.417-4.114-4.098-7.481-7.386-7.465-7.306 0 .063.174.444.38.873.207.413 1.557 2.748 5.655 9.641l-1.54 1.589c-.858.873-3.701 3.748-6.322 6.4-2.62 2.637-4.876 4.798-5.003 4.798-.127 0-5.178-4.94-11.198-10.976-6.02-6.02-11.31-11.261-12.516-12.294l.365.715c.206.397 1.54 2.684 2.954 5.083 1.43 2.398 2.605 4.447 2.605 4.527 0 .079-.921 1.096-4.13 4.288L9.233 55.15C4.34 50.258.338 46.192.338 46.097c0-.08 4.003-4.162 17.758-17.949l7.29 7.18c4.003 3.939 7.29 7.147 7.322 7.1.016-.032-.095-.334-.254-.652-.159-.333-1.445-2.589-2.859-5.003-1.413-2.43-2.589-4.51-2.605-4.606Z"
    />
    <path
      fill="#fff"
      d="M8.36 92 8.31 78.07v-.016c-.031-11.086 0-13.93.16-13.93.11 0 2.334 2.129 9.656 9.451l3.145-3.145c1.732-1.715 3.24-3.129 3.368-3.129.127 0 3.653 3.415 15.407 15.169l7.592-7.592c4.161-4.162 7.64-7.577 7.703-7.577.064 0 1.573 1.414 3.368 3.13 1.794 1.73 3.351 3.176 3.462 3.208.096.032 2.033-1.827 4.273-4.13 2.78-2.843 4.13-4.146 4.24-4.034.112.11.16 4.368.096 26.525H8.36Z"
    />
  </svg>
);
export default AgulaLogo;
