import Service from "./BaseService";

const createMiisPolicy = (payload) => Service.post(`/v2/miis/policy`, payload);

const LoginMiis = (payload) => Service.post(`/v2/miis/login`, payload);

const MiisService = {
  createMiisPolicy,
  LoginMiis,
};

export default MiisService;
