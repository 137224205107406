import Service from "./BaseService";

const getInsurancePartner = (param) =>
  Service.get(`/dynamicInsurance/get-insurance-partner-fields?${param}`);

const setInsurancePartner = (payload) =>
  Service.post(`/dynamicInsurance/set-insurance-partner`, payload);

/**
 * Аялалын даатгалын хэрэглэгчидыг бүртгэх api
 * @param {Object} payload аялагчдын мэдээлэл /нэр овог рд утас мэйл гадаад пасспортын дугаар/
 * @returns бүртгэгдсэн хэрэглэгчдийн мэдээлэл
 */
const setInsurancePartnerMany = (payload) =>
  Service.post(`/dynamicInsurance/set-insurance-partner-many`, payload);

const PartnerInfoService = {
  setInsurancePartner,
  getInsurancePartner,
  setInsurancePartnerMany,
};

export default PartnerInfoService;
