import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icon/search.svg";
import { ReactComponent as Bell } from "../../assets/icon/bell.svg";
import { ReactComponent as User } from "../../assets/icon/user.svg";
import { ReactComponent as Question } from "../../assets/icon/question.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log-out.svg";
import { ReactComponent as Gear } from "../../assets/icon/gear.svg";
import { ReactComponent as Fire } from "../../assets/icon/fire.svg";
import { ReactComponent as FillFire } from "../../assets/icon/fire-1.svg";
import { ReactComponent as Star } from "../../assets/icon/star.svg";
import { ReactComponent as FillStar } from "../../assets/icon/star-1.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right-1.svg";
import { Icon } from "../utils/menuIcons";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../stores";
import IconButton from "../../base-components/IconButton";
import Dropdown from "../../components/Dropdown";
import Typography from "../../base-components/Typography";
import CustomizedTooltip from "../../base-components/Tooltip";
import { ClickAwayListener } from "@mui/material";

const menus = [
  {
    id: "products",
    title: "Бүтээгдэхүүнүүд",
  },
  {
    id: "sales",
    title: "Борлуулалт",
  },
  {
    id: "doshReport",
    title: "Зуучлал тайлан",
  },
];

function Broker() {
  const navigate = useNavigate();
  const user = useStore.useUser();
  const clearUser = useStore.useClearUser();
  const token = useStore.useToken();
  const setToken = useStore.useSetToken();
  const selectedMenu = useStore.useSelectedMenu();
  const setSelectedMenu = useStore.useSetSelectedMenu();
  const [isShowing, setIsShowing] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [filteredMenus, setFilteredMenus] = useState([]);

  useEffect(() => {
    setFilteredMenus(
      menus.filter((menu) => {
        if (menu.id === "doshReport" && !user.show_company_report) {
          return false;
        }
        return true;
      }),
    );
  }, [user]);

  useEffect(() => {
    if (location.pathname === "/broker") setSelectedMenu("products");
    else setSelectedMenu(location.pathname.replace("/broker/", ""));
  }, [navigate]);

  useEffect(() => {
    if (token.length === 0) {
      navigate("login");
      return;
    }
    navigate("");
  }, [token]);

  const selectMenu = (menu) => {
    setSelectedMenu(menu.id);
    if (menu.id === "products") navigate("");
    else navigate(menu.id);
  };

  return (
    <div className="flex h-max min-h-full overflow-hidden">
      <div className="relative bg-info-soft-surface px-4 py-7 max-[500px]:hidden">
        {" "}
        {/* w-1/6 sm:w-1/5  */}
        <div className="px-4 py-2.5">
          <img
            src="/logos/horizontal-white.png"
            alt="horizontal-white-logo"
            className="hidden w-32 lg:block"
          />
          <img
            src="/logos/vertical-white.png"
            alt="vertical-white-logo"
            className="block w-14 lg:hidden"
          />
        </div>
        <div className="mt-16 p-4">
          {filteredMenus.map((menu, index) => (
            <CustomizedTooltip
              key={index}
              title={menu.title}
              placement="bottom-end"
              className="lg:hidden"
            >
              <div
                className={twMerge([
                  "mt-4 flex cursor-pointer items-center truncate rounded-lg p-4 transition-all hover:bg-white/[0.04] lg:py-3.5",
                  "w-min lg:w-52",
                  menu.id === selectedMenu.replace("/", "") &&
                    "bg-white/[0.08]",
                ])}
                onClick={() => selectMenu(menu)}
              >
                {Icon({
                  id: menu.id,
                  isSelected: menu.id === selectedMenu.replace("/", ""),
                })}
                <span
                  className={twMerge([
                    "transition-color ml-4 hidden text-on-surface-black-64",
                    "lg:block",
                    menu.id === selectedMenu.replace("/", "") &&
                      "text-secondary",
                  ])}
                >
                  {menu.title}
                </span>
              </div>
            </CustomizedTooltip>
          ))}
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between p-4 px-16 max-[500px]:px-4">
          <img
            src="/logos/sm.png"
            alt="sm-logo"
            className="hidden max-[500px]:block"
          />
          <div className="relative my-1 flex items-center max-[500px]:hidden">
            <SearchIcon stroke="#D9E0E8" className="absolute ml-2 h-5 w-5" />
            <input
              className="rounded-full border border-stroke-dark-12 bg-surface-gray px-4 py-2 pl-8 text-sm text-primary outline-none max-[840px]:w-10"
              placeholder="Хайх"
            />
          </div>
          <div className="flex items-center space-x-4 max-[840px]:space-x-2">
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div className="relative max-[500px]:hidden">
                <CustomizedTooltip
                  title="Зөвлөмж"
                  placement="bottom-end"
                  className="min-[840px]:hidden"
                >
                  <button
                    className={twMerge([
                      "group flex cursor-pointer rounded-full border p-2 px-4 hover:border-2 max-[840px]:px-2",
                      open && "border-2",
                    ])}
                    onClick={() => {
                      navigate("coming-soon");
                      setOpen((prev) => !prev);
                    }}
                  >
                    <Fire
                      stroke="#e9384d"
                      className={twMerge([
                        "h-5 w-5 group-hover:hidden",
                        open && "hidden",
                      ])}
                    />
                    <FillFire
                      fill="#e9384d"
                      className={twMerge([
                        "hidden h-5 w-5 group-hover:block",
                        open && "block",
                      ])}
                    />
                    <Typography
                      variant="Title"
                      size="md"
                      className="ml-2 max-[840px]:hidden"
                    >
                      Зөвлөмж
                    </Typography>
                  </button>
                </CustomizedTooltip>
                <Dropdown show={open} className="mt-0">
                  <Dropdown.Panel>
                    <Typography
                      size="md"
                      className="px-4 py-3.5 hover:cursor-pointer"
                    >
                      Зөвлөмжүүд
                    </Typography>
                    <Typography
                      size="md"
                      className="px-4 py-3.5 hover:cursor-pointer"
                    >
                      Хичээл
                    </Typography>
                    <Typography
                      size="md"
                      className="px-4 py-3.5 hover:cursor-pointer"
                    >
                      Заавар
                    </Typography>
                  </Dropdown.Panel>
                </Dropdown>
              </div>
            </ClickAwayListener>
            <CustomizedTooltip
              title="1,500"
              placement="bottom-end"
              className="min-[840px]:hidden"
            >
              <button
                onClick={() => navigate("coming-soon")}
                className={twMerge([
                  "group flex cursor-pointer rounded-full border p-2 px-4 outline-none hover:border-2 max-[840px]:px-2 max-[500px]:hidden",
                  open && "border-2",
                ])}
              >
                <Star className={twMerge(["h-5 w-5 group-hover:hidden"])} />
                <FillStar
                  className={twMerge(["hidden h-5 w-5 group-hover:block"])}
                />
                <Typography
                  variant="Title"
                  size="md"
                  className="ml-2 max-[840px]:hidden"
                >
                  1,500
                </Typography>
              </button>
            </CustomizedTooltip>
            <IconButton variant="outlinePrimary" size="sm">
              <Bell stroke="primary" className="h-5 w-5" />
            </IconButton>
            <ClickAwayListener onClickAway={() => setIsShowing(false)}>
              <>
                <button
                  className="flex items-center space-x-2"
                  onClick={() => setIsShowing((prev) => !prev)}
                >
                  <IconButton size="sm" variant="outlinePrimary">
                    <User stroke="primary" className="h-5 w-5" />
                  </IconButton>
                  <span className="max-[500px]:hidden">{user.user_name}</span>
                </button>
                <Dropdown show={isShowing} className="mt-6">
                  <Dropdown.Header>
                    <button className="flex items-center space-x-2">
                      <IconButton variant="outlinePrimary" size="sm">
                        <User stroke="primary" className="h-5 w-5" />
                      </IconButton>
                      <span>{user.user_name}</span>
                    </button>
                    <ChevronRight />
                  </Dropdown.Header>
                  <Dropdown.Panel>
                    <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                      <Question className="h-4 w-4" />
                      <Typography size="md" className="ml-4">
                        Тусламж
                      </Typography>
                    </div>
                    <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                      <Gear className="h-4 w-4" />
                      <Typography size="md" className="ml-4">
                        Тохиргоо
                      </Typography>
                    </div>
                    <div
                      className="flex items-center px-4 py-3.5 hover:cursor-pointer"
                      onClick={() => {
                        navigate("/login");
                        setToken("");
                        clearUser();
                      }}
                    >
                      <LogOut stroke="#B7281E" className="h-4 w-4" />
                      <Typography size="md" className="ml-4 text-error">
                        Гарах
                      </Typography>
                    </div>
                  </Dropdown.Panel>
                </Dropdown>
              </>
            </ClickAwayListener>
          </div>
        </div>
        <Outlet />
        <div className="fixed bottom-0  left-0 right-0 hidden justify-evenly bg-info-soft-surface max-[500px]:flex">
          {filteredMenus.map((menu, index) => (
            <CustomizedTooltip
              key={index}
              title={menu.title}
              placement="bottom-end"
            >
              <div
                className={twMerge([
                  "flex cursor-pointer flex-col items-center space-y-2 truncate rounded-lg p-4 transition-all hover:bg-white/[0.04] max-[353px]:my-2",
                  "w-min",
                  menu.id === selectedMenu.replace("/", "") &&
                    "bg-white/[0.08]",
                ])}
                onClick={() => selectMenu(menu)}
              >
                {Icon({
                  id: menu.id,
                  isSelected: menu.id === selectedMenu.replace("/", ""),
                })}
                <Typography
                  variant="Label"
                  size="md"
                  className={twMerge([
                    "transition-color max-[353px]:hidden",
                    menu.id === selectedMenu.replace("/", "")
                      ? "text-secondary"
                      : "text-on-surface-black-64",
                  ])}
                >
                  {menu.title}
                </Typography>
              </div>
            </CustomizedTooltip>
          ))}
        </div>
      </div>
      {/* <div className="bg-surface-gray p-8">
        <img src="/logos/horizontal-primary.png" alt="logo" />
      </div> */}
    </div>
  );
}

export default Broker;
