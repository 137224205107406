import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../../stores";

// Components
import Button from "../../../base-components/Button";
import IconButton from "../../../base-components/IconButton";
import Pagination from "../../../base-components/Pagination";
import Typography from "../../../base-components/Typography";
import Empty from "../../../base-components/Empty";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../base-components/Input";
import Modal from "../../../base-components/Modal";
import { ProductCardSkeleton } from "../../../components/Skeleton/ProductCardSkeleton";

// SVG
import MiisLogo from "../../../base-components/Icon/MiisLogo";
import { ReactComponent as Filter } from "../../../assets/icon/filter.svg";
// Service
import ProductService from "../../../services/ProductService";
import GroupConfigService from "../../../services/GroupConfigService";
import MiisService from "../../../services/MiisService";

import queryString from "query-string";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pages, setPages] = useState([]);
  const setPrevPath = useStore.useSetPrevPath();
  const location = useLocation();
  const navigate = useNavigate();
  const [isChangedTotalPage, setIsChangedTotalPage] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [miisV2Username, setMiisV2Username] = useState("");
  const [miisV2UsernameError, setMiisV2UsernameError] = useState("");
  const [miisV2Password, setMiisV2Password] = useState("");
  const [miisV2PasswordError, setMiisV2PasswordError] = useState("");
  const [clickedProduct, setClickedProduct] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    setLoader(true);
    let params = {
      page: pageNumber,
      limit: 8,
    };
    let payload = queryString.stringify(params);
    ProductService.getInsuranceProduct(payload)
      .then((response) => {
        if (response.status === "Success") {
          setProducts([...response.values]);
          setTotalPage(response.total_pages);
          let pages = [];
          for (let i = 0; i < response.total_pages; i++) {
            pages.push(i + 1);
          }
          setPages(pages);
          setIsChangedTotalPage(true);
        }
      })
      .catch((error) => {
        console.log(error, "ee buu uzegd");
      })
      .finally(() => setLoader(false));
  }, [pageNumber]);

  useEffect(() => {
    const savedUsername = localStorage.getItem("miisV2Username");
    const savedPassword = localStorage.getItem("miisV2Password");

    if (savedUsername && savedPassword) {
      setMiisV2Username(savedUsername);
      setMiisV2Password(savedPassword);
      setRememberMe(true);
    }
  }, []);
  const toInsure = (data) => {
    GroupConfigService.getGroupConfigLines(data.id)
      .then((response) => {
        if (response.status === "Success") {
          if (
            response?.step_config?.x_miis_policy_type_code !== false &&
            response?.has_miis_config === false
          ) {
            setClickedProduct(data);
            setDialogVisible(true);
          } else {
            navigate(`insurance-process/${data.id}`, {
              state: {
                has_service: data.has_service,
              },
            });
            setPrevPath(location.pathname);
          }
        } else if (response.status === "error") {
          useStore.setState({
            notification: {
              message: response.message,
              type: "warning",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const checkMiis = () => {
    let hasError = false;
    if (miisV2Username === "") {
      setMiisV2UsernameError("Миис нэвтрэх нэр заавал оруулах шаардлагатай.");
      hasError = true;
    }
    if (miisV2Password === "") {
      setMiisV2PasswordError("Миис нууц үг заавал оруулах шаардлагатай.");
      hasError = true;
    }
    if (hasError) {
      return;
    }

    // Намайг сана username password localstorage дээр хадгалах
    if (rememberMe) {
      localStorage.setItem("miisV2Username", miisV2Username);
      localStorage.setItem("miisV2Password", miisV2Password);
    } else {
      localStorage.removeItem("miisV2Username");
      localStorage.removeItem("miisV2Password");
    }

    let body = {
      username: miisV2Username,
      password: miisV2Password,
      is_save: false,
    };
    MiisService.LoginMiis(body)
      .then((response) => {
        if (response.status === "success") {
          navigate(`insurance-process/${clickedProduct.id}`, {
            state: {
              has_service: clickedProduct.has_service,
              miisV2Username: miisV2Username,
              miisV2Password: miisV2Password,
            },
          });
        } else if (response.status === "error") {
          useStore.setState({
            notification: {
              message: response.message,
              type: "warning",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  // input enter event
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      checkMiis();
    }
  };

  return (
    <div>
      <div className="flex justify-between border-b px-16 py-4 max-[841px]:px-10 max-[500px]:px-4">
        <div className="flex items-center text-primary">
          <Typography variant="Headline" size="md" className="truncate">
            Бүтээгдэхүүнүүд
          </Typography>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-4 border-r border-stroke-dark-12 pr-4 max-[1148px]:hidden">
            <FilterField />
          </div>
          <IconButton variant="outlinePrimary" onClick={() => setShow(!show)}>
            <Filter className="h-5 w-5 text-primary" />
          </IconButton>
          <Dropdown show={show} className="mt-7 min-[1149px]:hidden">
            <Dropdown.Panel className="py-4">
              <div className="space-y-2">
                <FilterField />
              </div>
            </Dropdown.Panel>
          </Dropdown>
        </div>
      </div>
      <div
        className={twMerge([
          "flex flex-wrap border-b px-14 py-4 text-primary max-[940px]:px-6 max-sm:justify-center max-[500px]:justify-center max-[500px]:px-3",
        ])}
      >
        <ProductCardSkeleton isloading={loader} count={8} />
        {!loader &&
          (products.length > 0 ? (
            products?.map((data) => (
              <div
                key={data.id}
                className="w-1/4 p-2 max-xl:w-1/3 max-md:w-1/2 max-sm:w-80 min-[1920px]:w-1/5"
              >
                <div className="group w-full cursor-pointer rounded-xl border p-6 shadow-card transition-all">
                  <div>
                    <div className="mb-4 flex h-14 w-14 items-center justify-center rounded-full border group-hover:border-secondary">
                      <img
                        src={data.logo || "/images/illustrations.png"}
                        alt="product"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <div className="min-h-[60px] animate-show text-sm text-on-surface-black-64 group-hover:hidden group-hover:animate-hide">
                      {data.name}
                    </div>
                    <h2 className="-mb-1 mt-2 animate-slide-down text-[22px] font-semibold group-hover:animate-slide-up">
                      {data.code}
                    </h2>
                  </div>
                  <div className="-mb-1 mt-6 hidden animate-hide items-center space-x-4 group-hover:flex group-hover:animate-show max-[347px]:space-x-2">
                    <Button className="w-1/2" variant="softPrimary">
                      Танилцуулга
                    </Button>
                    <Button className="w-1/2" onClick={() => toInsure(data)}>
                      Борлуулах
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex w-full justify-center py-10">
              <Empty value="Бүтээгдэхүүн байхгүй байна" />
            </div>
          ))}
      </div>
      {(isChangedTotalPage || (!loader && !isChangedTotalPage)) && (
        <div className="mt-8 flex justify-center">
          <Pagination current={pageNumber} totalPage={totalPage}>
            {pages.map((page, index) => (
              <Pagination.Link
                key={index}
                active={page === pageNumber}
                onClick={() => setPageNumber(page)}
              >
                {page}
              </Pagination.Link>
            ))}
          </Pagination>
        </div>
      )}
      <Modal
        isOpenModal={dialogVisible}
        title={"Миис баталгаажуулалт"}
        onClose={() => setDialogVisible(false)}
      >
        <div className="flex w-full items-center rounded-lg bg-white px-6 pt-2">
          <div className="mb-4 mr-10 flex w-full sm:mb-0 sm:w-1/4">
            <MiisLogo />
          </div>
          <div className="min-w-max sm:w-3/4">
            <div className="mb-4">
              <Input
                width="w-full sm:w-80"
                id="miis_v2_username"
                label="Миис нэвтрэх нэр"
                type="text"
                value={miisV2Username}
                className="rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={(e) => {
                  setMiisV2Username(e.target.value);
                  setMiisV2UsernameError("");
                }}
                onKeyDown={handleKeyDown}
              />
              {miisV2UsernameError && (
                <div className="mt-1">
                  <Typography variant="Body" size="sm" className="text-red-500">
                    {miisV2UsernameError}
                  </Typography>
                </div>
              )}
            </div>
            <div className="mb-4">
              <Input
                width="w-full sm:w-80"
                id="miis_v2_password"
                label="Миис нууц үг"
                type="password"
                value={miisV2Password}
                className="rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={(e) => {
                  setMiisV2Password(e.target.value);
                  setMiisV2PasswordError("");
                }}
                onKeyDown={handleKeyDown}
              />
              {miisV2PasswordError && (
                <div className="mt-1">
                  <Typography variant="Body" size="sm" className="text-red-500">
                    {miisV2PasswordError}
                  </Typography>
                </div>
              )}
            </div>
            <div className="mb-4 flex items-center justify-between">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="form-checkbox rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <Typography variant="Body" size="lg" className="text-gray-700">
                  Намайг санa
                </Typography>
              </label>
              <Button
                className="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white transition hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => checkMiis()}
              >
                Үргэлжлүүлэх
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const FilterField = () => {
  return (
    <>
      <div className="rounded-xl bg-secondary px-4 py-2.5 text-sm text-white">
        Бүгд
      </div>
      <div className="truncate rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Эрүүл мэнд
      </div>
      <div className="truncate rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Эд хөрөнгө
      </div>
      <div className="truncate rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Хариуцлага
      </div>
    </>
  );
};

export default Products;
