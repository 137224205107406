import Service from "./BaseService";

const uploadFile = (payload, config) =>
  Service.post(`/dynamicInsurance/uploadfile`, payload, config);

const IndemnityService = {
  uploadFile,
};

export default IndemnityService;
